import React from 'react';
import ReactDOM from 'react-dom';
import { compareDeep, delay, Nullable } from '@smd/utilities';
import { getMeridianDataLayerOrNull, type MeridianDataLayerValue } from '@smd/datalayer-typings';
import { AsyncEffect, log, useIsMounted } from '../utils';
import * as DataLayer from '../DataLayer';
import { PageMeta } from '../PageMeta';
import type { AdType } from '../AdType';
import { Context } from './Context';
import { from } from './from';
import type { Config } from '.';

export function use({
	endpoint,
	intermingleCount,
	columnsCount = 2,
	dehydratedConfig,
	supportedAdTypes,
	getMeridianDataLayer = getMeridianDataLayerOrNull,
}: use.Options) {
	const isMounted = useIsMounted();
	const [config, setConfig] = React.useState<Config>(Context.defaultValue);
	const dehydratedConfigRef = React.useRef(dehydratedConfig);
	const [dataLayer, refreshDataLayer] = DataLayer.use(getMeridianDataLayer);
	const lastConfigRefreshRequestTime = React.useRef<number>(Date.now());

	const refresh = React.useCallback(() => {
		const msSinceLastRefresh = Date.now() - lastConfigRefreshRequestTime.current;

		if (msSinceLastRefresh < 1000) {
			log.warn('REQUEST', 'API', 'AdRequest', 'Throttling config refresh', { msSinceLastRefresh });
			return;
		}

		log('REQUEST', 'API', 'AdRequest', 'Requesting config refresh');
		refreshDataLayer();

		lastConfigRefreshRequestTime.current = Date.now();
	}, [refreshDataLayer]);

	AsyncEffect.use(() => {
		if (!dataLayer) return;

		if (typeof intermingleCount !== 'number') {
			log.error('REQUEST', 'API', 'AdRequest', 'No intermingleCount set, aborting...');
			return;
		}

		if (dehydratedConfigRef.current) {
			const { current: dehydratedConfig } = dehydratedConfigRef;
			dehydratedConfigRef.current = null;

			log('REQUEST', 'API', 'AdRequest', 'Using dehydrated config', { dehydratedConfig });
			setConfig(dehydratedConfig);

			return;
		}

		const currentConfig = config;

		return {
			effect: async ({ abortSignal }) => {
				try {
					const options = {
						abortSignal,
						endpoint,
						dataLayer,
						supportedAdTypes,
						intermingleCount,
						columnsCount,
					} as const satisfies from.Options;

					log('REQUEST', 'API', 'AdRequest', 'Requesting config', { options });

					const nextConfig = await from(options);
					const logData = { currentConfig, nextConfig };

					if (abortSignal.aborted) {
						log.warn(
							'REQUEST',
							'API',
							'AdResponse',
							'Received config but cleanup ran, aborting...',
							logData,
						);
						return;
					}

					const configHasChanged = !compareDeep(currentConfig, nextConfig, {
						matchAnyCasingForStrings: true,
						trimStrings: true,
					});

					if (!configHasChanged) {
						log.warn(
							'REQUEST',
							'API',
							'AdResponse',
							'No config changes detected, aborting...',
							logData,
						);
						return;
					}

					const applyNextConfig = () => {
						if (!isMounted()) {
							log.warn(
								'REQUEST',
								'API',
								'AdResponse',
								'Config ready but component unmounted, aborting...',
								logData,
							);
							return;
						}

						log('REQUEST', 'API', 'AdResponse', 'Setting config', logData);

						// Flush the state update to get the new config into the context synchronously, so that
						// ad slots have a chance to complete rendering before we start requesting ads for them:
						ReactDOM.flushSync(() => setConfig(nextConfig));
					};

					if (!currentConfig.isEmpty && !nextConfig.isEmpty) {
						log('REQUEST', 'API', 'AdResponse', 'Config change imminent, resetting', logData);

						// Flush the state update to get the empty config into the context synchronously,
						// so that ad slots have a chance to complete unmounting before we load the new config:
						ReactDOM.flushSync(() => setConfig(Context.emptyValue));

						delay(0, abortSignal).then(applyNextConfig, (error: unknown) =>
							log.error('REQUEST', 'API', 'AdResponse', 'Failed after config reset', { error }),
						);
					} else {
						applyNextConfig();
					}
				} catch (error) {
					if (abortSignal.aborted) {
						log.warn('REQUEST', 'API', 'AdRequest', 'Cleanup ran, aborting...', { error });
					} else {
						log.error('REQUEST', 'API', 'AdRequest', 'Failed', { error });
					}
				}
			},
		};
	}, [dataLayer]);

	const pageMeta = PageMeta.use(dataLayer);

	return [config, refresh, pageMeta] as const;
}

export namespace use {
	export type Options = {
		/** The endpoint to use for fetching advertising configuration. */
		endpoint: string;

		/** The total number of listings on the page where an intermingle ad can be displayed. */
		intermingleCount: Nullable<number>;

		/** The number of columns in the listing grid. */
		columnsCount?: number;

		/** The ad types that are supported by the page. */
		supportedAdTypes: Array<AdType.Supported>;

		/** A function that returns the dataLayer. */
		getMeridianDataLayer?(): Nullable<MeridianDataLayerValue>;

		/** The configuration to use for hydration. */
		dehydratedConfig?: Nullable<Config>;
	};
}
